html {
  height: 100%;
}

body {
  height: 100%;
  font-family: 'DM Sans', sans-serif;
}

/* Medications Modal */

.medications-modal.ant-modal {
  padding: 0px;
  width: 800px !important;
}

.medications-modal.main-modal.ant-modal .ant-modal-header {
  padding: 22px 30px 22px;
}

.medications-select-option.ant-select-item {
  padding: 0px;
}

.medications-select-option .ant-select-item-option-content {
  white-space: break-spaces;
  padding: 5px 13px;
  border-bottom: 1px solid #f2f2f2;
  text-align: left;
  font: normal normal normal;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0px;
  opacity: 1;
}

/* Investigations Modal */

.investigations-modal.ant-modal {
  padding: 0px;
  width: 800px !important;
}

.investigations-modal.main-modal.ant-modal .ant-modal-header {
  padding: 22px 30px 22px;
}

/* Examinations Modal */

.examination-modal.ant-modal {
  padding: 0px;
  width: 676px !important;
}

/* General modal */


.main-modal.ant-modal .ant-modal-content {
  border-radius: 10px;
  border: 1px solid #707070;
}

.main-modal.ant-modal .ant-modal-header {
  border-radius: 10px 10px 0 0;
  padding: 22px 51px 22px;
  border-bottom: 1px solid #002E4640;
}

.main-modal.ant-modal .ant-modal-title {
  text-align: left;
  font: normal normal medium 18px/22px DM Sans;
  font-weight: 400;
  letter-spacing: 0px;
  color: #002E46;
  opacity: 0.75;
}

.main-modal .ant-modal-body {
  padding: 0px !important;
  max-height: calc(100vh - 100px);
}

.main-modal.ant-modal .ant-modal-footer {
  padding: 22px 51px 22px;
  border-radius: 0px 0px 10px 10px;
  border-top: 1px solid #002E4640;
}

.main-modal.ant-modal .ant-modal-footer .ant-btn {
  padding: 10px 20px;
  height: auto;
  border-radius: 6px;
}

/* Remove Broswer Autofill background */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
 -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.no-margin {
  margin: 0;
}

.no-padding {
  padding: 0;
}

/* Popover Styles */
.password-hints-popover.ant-popover .ant-popover-content .ant-popover-inner {
  background-color: #E0EEFB;
  box-shadow: 0px 1px 3px #002E4640;
  border-radius: 5px;
  text-align: left;
  font: normal normal normal 13px/13px DM Sans;
  letter-spacing: 0px;
  color: #002E46;
}

.password-hints-popover.ant-popover .ant-popover-content .ant-popover-arrow {
  border-right-color: #E0EEFB;
  border-bottom-color: #E0EEFB;
 }

.password-hints-popover.ant-popover .ant-popover-content .ant-popover-inner p.title {
  font-weight: bold;
}

/* Chat More menu */
.more-menu .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
  font: normal normal medium 13px/9px DM Sans;
  letter-spacing: 0px;
  color: #002E46D9;
  opacity: 1;
}

.more-menu .ant-dropdown-menu-item.end-chat {
  color: #FF2366;
}

.ant-table-pagination.ant-pagination {
  margin: 16px 40px !important;
}

.sidebar-drawer .ant-drawer-body {
  padding: 0px !important;
} 

.sidebar-drawer .ant-drawer-body aside {
  position: relative !important;
  display: block;
  box-shadow: none;
  margin-top: 0px;
  height: auto;
}

.sidebar-drawer .ant-drawer-body aside ul {
  padding: 0px 20px;
}

@media(max-width: 450px) {
  .form-drawer .ant-drawer-content-wrapper, .lab-request-drawer .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}

.dashboard-mode-modal .ant-modal-content {
  border-radius: 6px;
}

.dashboard-mode-modal .ant-modal-header {
  border-radius: 6px 6px 0 0;
}

.dashboard-mode-modal .ant-modal-footer {
  padding: 20px 25px;
}